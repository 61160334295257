<template>
  <div class="q-pa-sm">
    <q-table
        style="height: 90vh;"
        row-key="id"
        :title="options.title"
        :rows="items"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="isLoading"
        :filter="filter"
        virtual-scroll
        binary-state-sort
        @request="onRequest"
    >
      <template v-slot:top>
        <div class="row border-bottom full-width q-pb-sm">
          <h5 class="q-my-none">
            {{ options.title }}
          </h5>

          <q-space />

          <q-btn
              :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
              text-color="white"
              size="sm"
              class="q-mr-sm"
              :label="filterBtnText"
              no-caps
              unelevated
              @click="openCloseFilters"
          />

          <q-btn
              color="dark"
              text-color="white"
              :label="$t('Refresh')"
              size="sm"
              class="q-mr-sm"
              no-caps
              unelevated
              @click="refreshItems"
          />

          <q-btn
              color="light-blue-9"
              text-color="white"
              icon="add"
              size="sm"
              no-caps
              unelevated
              @click="create"
          />
        </div>

        <div class="full-width">
          <filter-collapse
              :is-open="isOpenFilter"
              :options="{
              defaultFilter: serverParams.filter,
              fields: activatedFields,
              values: {
                states: statuses
              },
              style: {
                noGroups: true
              }
            }"
              @submit="handleFiltersSubmit"
              @close="openCloseFilters"
          />
        </div>
      </template>

      <template
          v-slot:body="props"
          class="clickable"
      >
        <q-tr
            :props="props"
            class="clickable"
            @dblclick="onRowDblClick(props.row)"
        >
          <q-td
              key="id"
              :props="props"
          >
            {{ props.row.id }}
          </q-td>

          <q-td
              key="name"
              :props="props"
          >
            {{ props.row.name }}
          </q-td>


          <q-td
              key="created"
              :props="props"
          >
            {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
          </q-td>

          <q-td
              key="settings"
              :props="props"
          >
            <q-btn
                color="dark"
                icon="settings"
                size="sm"
                round
                @click="handleSettingsOpen(props.row)"
            />
          </q-td>

          <q-td
              key="state"
              :props="props"
          >
            <q-toggle
                color="light-blue-9"
                :model-value="props.row.state === 'active'"
                @update:model-value="handleStateChange(props.row)"
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <queue-modal
        ref="queueModal"
        @submit="handleSubmit"
    />

    <queue-settings-modal
        ref="settingsModal"
        @submit="handleSettingsSubmit"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import QueueModal from '../../components/modals/QueueModal.vue'
import QueueSettingsModal from '../../components/modals/QueueSettingsModal.vue'
import FilterCollapse from '../../components/filters/FilterCollapse.vue'

// Mixins
import TableMixin from './../../components/global/TableMixin'

// Utils
import { buildQuery } from './../../utils/query-utils'

export default {
  name: 'TaskQueuesPreprocessing',
  emits: ['select'],
  components: {
    QueueModal,
    QueueSettingsModal,
    FilterCollapse
  },
  mixins: [
    TableMixin
  ],
  props: {
    options: {
      type: Object,
      default () {
        return {
          adapters: [],
          title: '',
          service: null
        }
      }
    }
  },
  data () {
    return {
      isOpenFilter: false,
      filter: '',
      items: [],
      isLoading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark'
      },
      activatedFields: [
        'id',
        'name',
        'state',
        'created.from',
        'created.to'

      ],
      isNew: false,
      totalNumber: 0
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    columns () {
      return [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Settings'),
          name: 'settings',
          align: 'left',
          headerClasses: this.options.settings
              ? ''
              : 'd-none',
          classes: this.options.settings
              ? ''
              : 'd-none'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'center'
        }
      ]
    },
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ') + this.totalNumber
          : this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    upsertItem (item) {
      let hasItem = false

      this.items = this.items.map(x => {
        if (x.id === item.id) {
          hasItem = true
          return item
        }

        return x
      })

      if (!hasItem) {
        this.items = [item, ...this.items]
      }

      return this.items
    },
    handleStateChange (item) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[item.state]

      return this.options.service.save({ state }, item.id)
          .then(queue => {
            this.upsertItem(queue)
          })
    },
    handleFiltersSubmit (filter) {
      return this.onRequest({ pagination: { filter, page: 1, per_page: 25 } })
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    onRequest (data = {}) {
      this.isLoading = true
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (!query.filter) {
        query.filter = [
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      if (this.options.adapters && this.options.adapters.length > 0) {
        query.filter = query.filter.filter(x => x.field !== 'adapter')
        query.filter.push({ type: 'in', field: 'adapter', values: this.options.adapters })
      }

      this.updateParams(query)
      return this.options.service.getAll(this.serverParams)
          .then(({ page, totalItems, items }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.totalNumber = totalItems
            this.items = items
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    create () {
      this.$refs.queueModal.open(this.options.service, this.options.type)
    },
    handleSettingsOpen (queue, isNew = false) {
      this.isNew = isNew
      this.$refs.settingsModal.open(queue, this.options.service, { ...this.options, isNew })
    },
    handleSubmit (queue) {
      this.$refs.queueModal.close()
      this.upsertItem(queue)

      if (this.options.settings) {
        this.handleSettingsOpen(queue, true)
      } else {
        this.onRowDblClick(queue, true)
      }
    },
    handleSettingsSubmit (queue) {
      this.$refs.settingsModal.close()
      this.upsertItem(queue)

      if (this.isNew) {
        this.onRowDblClick(queue, true)
      }
    },
    onRowDblClick (item, toFilters) {
      this.$emit('select', { item, toFilters })
    }
  }
}
</script>
